<template>
  <div class="animated fadeIn">
    <b-card>
      <template v-slot:header>
        <h5>Tambah Pembelian</h5>
      </template>
      <b-form @submit.prevent="submitForm">
        <b-row align-h="between">
          <b-col sm="4">
            <img :src="$logoPath" width="200px" />
          </b-col>
        </b-row>
        <b-row class="my-3 mx-1">
          <b-col lg="6">
            <p><strong>From</strong></p>
            <p>{{ supplier.nama }}</p>
            <p>{{ supplier.alamat }}</p>
          </b-col>
          <b-col lg="6">
            <p><strong>To</strong></p>
            <p>Pusat Gadai Indonesia</p>
            <p>Jl. Arteri Klapa Dua Kav.12A, Kebon Jeruk</p>
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col sm="4">
            <b-form-group label="Tipe Supplier" label-for="typeSupplier">
              <b-form-select @change="getListSupplier" id="typeSupplier" v-model="form.is_online"
                :options="typePembelianOptions"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group label="Supplier">
              <multiselect :disabled="isLoading" :loading="isLoading" @select="handleSelectSupplier" v-model="supplier"
                :options="supplierAll" :internal-search="true" :allow-empty="false" deselect-label="" select-label=""
                label="nama" track-by="id" placeholder="Suplier" />
              <small v-if="$v.form.id_supplier.$error" class="text-danger">
                Silahkan pilih suplier
              </small>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group v-if="form.is_online === 2" label="Nama Toko" label-for="shop_name">
              <b-form-input :disabled="showShopName" id="shop_name" v-model="form.shop_name"
                placeholder="Masukan Nama Toko"></b-form-input>
            </b-form-group>
            <b-form-group v-if="form.is_online === 1" label="Nama Toko" label-for="shop_name">
              <multiselect @select="handleSelectMerchat" :disabled="isLoading" :loading="isLoading" v-model="merchat"
                :options="merchatfiltered" :internal-search="true" :allow-empty="false" deselect-label="" select-label=""
                label="nama_merchant" track-by="id" placeholder="Merchant" />
            </b-form-group>
          </b-col>

          <!-- ADA KONDISI JIKA ONLINE TULIS BANK DAN REKENING ATAU VA -->
          <!-- NAMA PEMILIK REKENING -->
          <b-col md="4">
            <b-form-group label="Pilih Bank" label-for="bank_id">
              <b-form-select id="bank_id" v-model="form.bank_id">
                <option :value="null" disabled>pilih bank</option>
                <option v-for="item in bankOptions" :key="item.id" :value="item.id">
                  {{ item.nama_bank }}
                </option>
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Nama Rekening" label-for="nama_rekening" class="required">
              <b-form-input :disabled="showShopName" id="nama_rekening" v-model="form.nama_rekening"
                placeholder="Masukan Nama Pemilik"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="No Rekening / virtual akun" label-for="nomor_rekening" class="required">
              <b-form-input :disabled="showShopName" id="nomor_rekening"
                :class="$v.form.no_rekening.$error ? 'is-invalid' : ''" v-model="form.no_rekening"
                placeholder="Masukan Rekening / Virtual Akun"></b-form-input>
            </b-form-group>
          </b-col>
          <!-- <b-col sm="4">
            <b-form-group label="Bukti Pembelian / Nota" label-for="file">
              <b-form-file accept=".jpg, .png, .pdf" multiple id="file" v-model="form.file" placeholder="Choose a file"
                drop-placeholder="Drop file here..."></b-form-file>
            </b-form-group>
          </b-col> -->
          <!-- ADA KONDISI JIKA ONLINE TULIS BANK DAN REKENING ATAU VA -->

          <b-col sm="4">
            <b-form-group label="Tanggal" label-for="tanggal">
              <date-picker :config="configDate" placeholder="Pilih Tanggal" v-model="form.tanggal"></date-picker>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group label="Ongkos Kirim" class="required">
              <input class="form-control" :class="$v.form.ongkir.$error ? 'is-invalid' : ''" v-model="form.ongkir"
                id="onkir" type="text" placeholder="Rp" />
              <small>Masukan angka 0 (nol) jika tidak ada</small>
            </b-form-group>
          </b-col>
          <!-- <b-form-group label="Bukti Pembelian / Nota" label-for="file">
              <b-form-file accept=".jpg, .png, .pdf" multiple id="file" v-model="form.file" placeholder="Choose a file"
                drop-placeholder="Drop file here..."></b-form-file>
            </b-form-group> -->
          <b-col sm="12" class="mt-4">
            <div class="mb-4">
              <div>Upload Bukti Invoice</div>
              <small>Silahkan upload file <b class="text-danger">pdf atau image</b> saja!</small>
            </div>
            <div class="row">
              <div class="col-3 col-md-2 mb-3" v-for="item in imagePreview" :key="item.preview">
                <div class="image-preview-container">
                  <button type="button" @click="removePreviewImage(item.preview, item.file)" class="btn-remove-image">
                    <i class="fa fa-times"></i>
                  </button>
                  <expandable-image v-if="item.file.type.includes('image')" class="image-preview" :src="item.preview" />
                  <div v-if="item.file.type.includes('pdf')"
                    class="image-preview file-or-pdf d-flex justify-content-center align-items-center">
                    <i class="fa fa-file-pdf-o"></i>
                  </div>
                </div>
              </div>
              <div class="col-3 col-md-2 mb-3">
                <label for="images" class="choose-image-action">
                  <input multiple @change="handleChangeImages" type="file" accept=".jpg, .png, .pdf" id="images"
                    class="d-none">
                  <i class="fa fa-file icon-choose"></i>
                </label>
              </div>
            </div>
          </b-col>
          <b-col sm="12">
            <b-form-group label="Keterangan" label-for="keterangan">
              <b-form-textarea v-model="form.keterangan" id="keterangan" placeholder="Keterangan" rows="3"
                max-rows="6"></b-form-textarea>
            </b-form-group>
          </b-col>
          <!-- HIDE SEMENTARA CHECKLIST DROPSHIP -->
          <!-- <b-col sm="4">
            <b-form-checkbox v-model="form.is_dropship" id="is_dropship" name="is_dropship">
              Dropship
            </b-form-checkbox>
          </b-col>
          <b-col v-if="form.is_dropship">
            <b-form-group>
              <multiselect @select="handleChangeCabang" v-model="form.cabang" :options="cabangOptions"
                :internal-search="true" :allow-empty="false" deselect-label="" select-label="" label="name" track-by="id"
                placeholder="Suplier" />
            </b-form-group>
          </b-col> -->
        </b-row>
        <hr />
        <div class="d-flex justify-content-end">
          <b-button type="button" onclick="history.back()" class="mr-2">Batal</b-button>
          <b-button :disabled="isSubmitting" type="submit" variant="primary">
            <i v-if="isSubmitting" class="fa fa-spin fa-spinner"></i>
            Selanjutnya
          </b-button>
        </div>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import axiosClient from "../../../../config/api";
import { validationMixin } from "/node_modules/vuelidate";
import { required } from "/node_modules/vuelidate/lib/validators";
export default {
  name: "tambah-pembelian",
  mixins: [validationMixin],
  data() {
    return {
      initStatus: 0,
      isBusy: true,
      isSubmitting: false,
      isLoading: false,
      supplier: {},
      cabang: {},
      cabangOptions: [],
      showShopName: false,
      bankOptions: [],
      imagePreview: [],
      configDate: {
        format: "DD MMM YYYY",
        useCurrent: true,
        minDate: new Date(),
        maxDate: new Date(),
      },
      form: {
        tanggal: new Date(),
        id_supplier: null,
        typePembelian: null,
        pic: "0999",
        ongkir: null,
        file: [],
        flag: 0,
        keterangan: null,
        is_online: null,
        shop_name: null,
        is_dropship: null,
        cabang_kode: null,
      },
      typePembelianOptions: [],
      eccomerseOptions: [
        { value: null, disabled: true, text: "Pilih Jenis Eccomerce" },
        { value: "tokopedia", text: "Tokopedia" },
        { value: "shopee", disabled: true, text: "Shopee" },
      ],
      supplierAll: [],
      merchat: {},
      merchatfiltered: [],
      supplierDetail: {
        nama: null,
        alamat: null,
        no_hp: null,
        link_web: null,
        nama_bank: null,
        nomor_rekening: null,
      }
    };
  },
  validations: {
    form: {
      tanggal: {
        required
      },
      id_supplier: {
        required
      },
      ongkir: {
        required
      },
      keterangan: {
        required
      },
      bank_id: {
        required
      },
      no_rekening: {
        required
      }
    },
  },
  methods: {
    init() {
      this.initStatus = 0;
      this.page = 1;
      this.query = "?page=" + this.page;
      let getCabang = this.$axios
        .get("api/admin/cabang/all")
        .then((response) => {
          this.cabangOptions = response.data.data;
        });

      let getTypeSupplier = this.$axios.get("api/supplier-type/all").then((response) => {
        const data = response.data.data;
        const labelSelect = [
          { value: null, text: 'pilih tipe supplier' }
        ]
        const options = data.map(item => {
          return {
            ...item,
            value: item.id,
            text: item.type
          }
        })
        this.typePembelianOptions = [...labelSelect, ...options,]
      });

      let getBankAll = this.$axios.get("api/admin/bank").then((response) => {
        const data = response.data.data;
        this.bankOptions = data.map(item => {
          return {
            ...item,
            value: item.id,
            text: item.type
          }
        })
      });


      Promise.all([getCabang, getTypeSupplier, getBankAll])
        .then(() => {
          this.initStatus = 1;
          let msg = this.$helper.getFlash();
          this.$helper.toastSucc(this, msg);
        })
        .catch((error) => {
          console.log(error);
          this.initStatus = -1;
        });
    },

    handleChangeImages(e) {
      // this.fileErrorMessage = ''
      const files = e.target.files;
      this.createPreviewImage(files)
    },

    createPreviewImage(files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        this.form.file.push(file);
        let blobURL = URL.createObjectURL(file);

        this.imagePreview.push({
          preview: blobURL,
          file: file
        })
      }
    },

    removePreviewImage(preview, file) {
      const newPreview = this.imagePreview.filter(i => i.preview !== preview)
      const newFile = this.form.file.filter(i => i.name !== file.name)
      this.imagePreview = newPreview
      this.form.file = newFile
    },

    async getListSupplier(value) {
      try {
        this.isLoading = true
        const response = await axiosClient({
          method: 'GET',
          url: 'supplier/all',
          params: { type_id: value }
        })
        const data = response.data.data;
        this.supplierAll = data
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    handleSelectMerchat(value) {
      this.form.shop_name = value.nama_merchant
    },
    async filterSupplier(value) {
      this.form.typePembelian = value;
      this.form.shop_name = null
      try {
        const responses = await axiosClient({
          method: 'GET',
          url: 'supplier/all'
        })
        const suppliers = responses.data.data
        let dataSupplier;
        if (value === 1) {
          // ON
          this.showShopName = true
          dataSupplier = suppliers.filter(item => {
            return item.id === 7 || item.id === 24
          })
        } else {
          // ON
          this.showShopName = false
          dataSupplier = suppliers.filter(item => {
            return item.id !== 7 && item.id !== 24
          })
        }
        this.supplierAll = dataSupplier
      } catch (error) {
        console.log(error)
      }
    },
    handleChangeCabang(value) {
      this.form.cabang_kode = value.kode
    },
    handleSelectSupplier(value) {
      if (value.supplier_type.type.toLowerCase() === 'online') {
        this.showShopName = false
        this.form.shop_name = null
        this.form.no_rekening = null;
        this.form.nama_rekening = value.nama;
        this.form.bank_id = null;
        this.form.is_online = 1;
        const supplier_id = value.id
        this.merchatfiltered = this.merchants.filter(item => {
          if (item.supplier_id === supplier_id && item.status === 2)
            return item
        })
      } else {
        this.showShopName = true
        this.form.shop_name = value.nama
        this.form.no_rekening = value.no_rekening;
        this.form.nama_rekening = value.nama_rekening;
        this.form.bank_id = value.bank.id;
        this.form.is_online = 2;
      }
      this.supplierDetail = {
        nama: value.name,
        alamat: value.alamat,
        no_hp: value.no_hp,
        link_web: value.link_web,
        nomor_rekening: value.nomor_rekening || "-",
        nama_bank: value.nama_bank || "-"
      }
      this.form.id_supplier = value.id;
    },
    submitForm: function () {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        window.scrollTo(0, 0);
        this.$helper.toastErr(this, "Silahkan lengkapi form invoice!")
        this.isLoading = false
        return;
      }
      const newForm = ({
        ...this.form,
        tanggal: this.$moment(this.form.tanggal).format('YYYY-MM-DD'),
        is_dropship: this.form.is_dropship || false,
        ongkir: this.form.ongkir.split(".").join("")
      })
      this.handleSubmitInvoice(newForm)
    },
    async handleSubmitInvoice(form) {
      // console.log(form)
      // return
      let formData = new FormData();
      const isDropsip = form.is_dropship ? 1 : 2
      formData.append('tanggal', form.tanggal);
      formData.append('id_supplier', form.id_supplier);
      formData.append('is_online', form.is_online);
      formData.append('shop_name', form.shop_name);
      formData.append('pic', form.pic);
      formData.append('ongkir', form.ongkir);
      formData.append('flag', form.flag);
      formData.append('keterangan', form.keterangan);
      formData.append('bank_id', form.bank_id);
      formData.append('no_rekening', form.no_rekening);
      formData.append('nama_rekening', form.nama_rekening);
      formData.append('is_dropship', isDropsip);
      formData.append('cabang_kode', form.cabang_kode);
      if (form.file.length) {
        for (let i = 0; i < form.file.length; i++) {
          const item = form.file[i];
          formData.append(`file[${i}]`, item);
        }
      }
      try {
        this.isSubmitting = true
        const response = await axiosClient({
          method: 'POST',
          url: 'transaksi/pembelian',
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: formData
        })

        const newId = response.data.data.id;
        this.$router.push(`/input-data/pembelian/${newId}/detail`)

      } catch (error) {
        console.log(error)
      } finally {
        this.isSubmitting = false
      }
    }
  },

  watch: {
    "form.ongkir": function (newValue) {
      console.log(newValue)
      let cleanValue = this.$helper.changeToValue(newValue);
      const result = cleanValue
        .replace(/(?!^-)[^0-9]/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      this.$nextTick(() => (this.form.ongkir = result));
    },
    "form.no_rekening": function (newValue) {
      let cleanValue = this.$helper.changeToValue(newValue);
      const result = cleanValue
        .replace(/(?!^-)[^0-9]/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, "");
      this.$nextTick(() => (this.form.no_rekening = result));
    },
  },
  computed: {
    // suppliers() {
    //     return this.$store.state.supplier.master_suppliers
    // },
    merchants() {
      const data = this.$store.state.supplier.master_merchants
      return data
    }
  },
  created() {
    this.$store.dispatch("getMasterMerchant")
    this.init();
  },
};
</script>